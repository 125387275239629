import React from 'react'

import { useI18n } from '@elo-kit/components/i18n/i18n'
import { ConfirmationModal } from '@elo-kit/components/elo-ui/confirmation-modal'

const ConfirmationModalBlock = (props) => {
  const I18n = useI18n()

  return (
    <ConfirmationModal
      {...props}
      submitButtonChild={props.submitButtonChild || I18n.t('react.shared.button.yes')}
      cancelButtonChild={
        props.cancelButtonChild ||
        (props.submit ? I18n.t('react.shared.button.cancel') : I18n.t('react.shared.button.close'))
      }
    />
  )
}

export default ConfirmationModalBlock
