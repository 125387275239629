import React, { Component } from 'react'
import { action, observable, makeObservable } from 'mobx'
import { observer } from 'mobx-react'

import EloModal from 'ui/EloModal'
import { LoadingSpinner, CheckboxField, WarningInfo, TextField } from '@elo-kit/components'

@observer
class LicenseKeyModal extends Component {
  @observable licenseKey = ''
  @observable termsAccepted = false
  @observable isOpen = true
  @observable valid = true
  @observable loading = false

  @action setLicenseKey = (value) => (this.licenseKey = value)
  @action setTermsAccepted = (value) => (this.termsAccepted = value)
  @action setValid = (value) => (this.valid = value)
  @action toggleModal = () => (this.isOpen = !this.isOpen)
  @action setLoading = (value) => (this.loading = value)

  onSubmit = async () => {
    const { submit } = this.props
    this.setLoading(true)
    const success = await submit({ key: this.licenseKey })
    this.setLoading(false)
    if (success) {
      this.setValid(true)
      this.toggleModal()
      return
    }
    this.setValid(false)
  }

  onCancel = () => {
    this.props.cancel()
    this.toggleModal()
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  render() {
    const { sharingSubmitEnabled } = this.props
    const licenseKeyLength = this.licenseKey.trim().length
    const warningMessage = sharingSubmitEnabled
      ? I18n.t('react.cabinet.themes.apply_theme_warning')
      : I18n.t('react.cabinet.themes.apply_theme_loading_warning')

    return (
      <EloModal
        isOpen={this.isOpen}
        title={I18n.t('react.cabinet.themes.share_confirmation.license_code.title')}
        toggle={this.toggleModal}
        submit={this.onSubmit}
        onCancel={this.onCancel}
        onClose={this.onCancel}
        close={!sharingSubmitEnabled || !this.loading}
        cancel={!sharingSubmitEnabled || !this.loading}
        onlySubmit
        submitDisabled={!this.termsAccepted || !licenseKeyLength || this.loading || !sharingSubmitEnabled}
        submitButtonChild={I18n.t('react.shared.button.apply')}
        headerIcon={
          <i
            className='
              fas fa-lock
              elo-modal__header-icon
              elo-modal__header-icon--lock
            '
          />
        }
      >
        <EloModal.Message>{I18n.t('react.cabinet.themes.share_confirmation.license_code.message')}</EloModal.Message>
        <WarningInfo message={warningMessage} />
        <TextField
          value={this.licenseKey}
          onChange={(value) => {
            this.setLicenseKey(value)
            this.setValid(true)
          }}
          placeholder={I18n.t('react.cabinet.common.license_key')}
          isInvalid={!this.valid}
          prefix={<i className='fas fa-key prefix-icon' />}
          prefixNormalField
          prefixInsideField
        />
        <CheckboxField
          checked={this.termsAccepted}
          label={I18n.t('react.cabinet.themes.share_confirmation.license_code.terms')}
          onChange={() => this.setTermsAccepted(!this.termsAccepted)}
        />
        {this.loading && <LoadingSpinner />}
      </EloModal>
    )
  }
}

export default LicenseKeyModal
