import { SHARABLE_TYPE } from 'constants/sharing.constants'

export const SHARING_ITEMS_URLS = {
  [SHARABLE_TYPE.product]: '/cabinet/products',
  [SHARABLE_TYPE.affiliateLanding]: '/cabinet/affiliate_landings',
  [SHARABLE_TYPE.funnel]: '/cabinet/funnels',
  [SHARABLE_TYPE.paymentPage]: '/cabinet/payment_page_templates',
  [SHARABLE_TYPE.addon]: '/cabinet/order_bumps_templates',
  [SHARABLE_TYPE.shop]: '/cabinet/shop_themes',
  [SHARABLE_TYPE.membership]: '/cabinet/membership_themes',
}

export const VIBAN_PAYMENTS_TAB = '/cabinet/viban_payments_tab'
