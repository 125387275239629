import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { action, observable, makeObservable } from 'mobx'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { isWindowEnv } from 'utils/env.utils'

const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage

@observer
class FunctionalLimitationsModal extends Component {
  @observable isOpen =
    window.innerWidth < 768 && sessionStorage && !sessionStorage.getItem('functionalLimitationsModal')

  @action setClosed = () => {
    this.isOpen = false
    if (sessionStorage) sessionStorage.setItem('functionalLimitationsModal', 'true')
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  render() {
    return (
      <Modal isOpen={this.isOpen} className='elo-modal elo-modal--small functional-limitations-modal'>
        <ModalHeader>
          <div className='functional-limitations-modal__title'>
            <i className='fas fa-exclamation-triangle' />
            <div>{I18n.t('react.cabinet.functional_limitations_modal.title')}</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='functional-limitations-modal__message'>
            {I18n.t('react.cabinet.functional_limitations_modal.message')}
          </div>
          <div className='elo-modal__bottom-buttons'>
            <button onClick={this.setClosed} className='elo-btn outline-white'>
              {I18n.t('react.shared.button.close')}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default FunctionalLimitationsModal
