import React from 'react'
import { Link } from 'react-router-dom'

import EloModal from 'ui/EloModal'

import { SHARING_ITEMS_URLS } from 'constants/routes/cabinet.constants'

import { getProductForm } from 'utils/product.utils'
import { createId } from '@elo-kit/utils/general.utils'

const MultipleItemsSharingSuccessModal = (props) => {
  const { toggle, sharingItems = [] } = props
  return (
    <EloModal
      isOpen
      title={I18n.t('react.cabinet.multiple_items_sharing.success_import_modal.title')}
      toggle={toggle}
      iconProps={{ className: 'fas fa-check-circle' }}
      success
    >
      <EloModal.Message centered>
        {I18n.t('react.cabinet.multiple_items_sharing.success_import_modal.message')}
      </EloModal.Message>
      <div className='fields-container--column'>
        {(sharingItems || []).map((sharingItem) => {
          const { sharable: { id: sharableId, name, form } = {}, sharableType, id } = sharingItem

          return (
            <div
              key={createId(id, sharableId)}
              className='fields-container fields-container--column fields-container--center-align fields-container--bottom-25--without-last'
            >
              <Link to={`${SHARING_ITEMS_URLS[sharableType]}?id=${sharableId}`} onClick={toggle}>
                <span className='elo-title elo-title--underlined'>{name}</span>
              </Link>
              <span className='elo-regular-text elo-regular-text--grey elo-regular-text--italic'>
                {getProductForm(form) || sharableType}
              </span>
            </div>
          )
        })}
      </div>
    </EloModal>
  )
}

export default MultipleItemsSharingSuccessModal
