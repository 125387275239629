import { observable, makeObservable, action } from 'mobx'

import { PaymentCountriesApi, createPaymentCountriesApi, PaymentCountry } from '../api/paymentCountries.api'

export class PaymentCountriesStore {
  storeName = 'PaymentCountriesStore'
  declare childApi: PaymentCountriesApi
  root: any

  constructor(root: any) {
    this.root = root
    this.childApi = createPaymentCountriesApi(root.apiClient)
    makeObservable(this)
  }

  @observable paymentCountries = [] as PaymentCountry[]
  @observable loading = true

  @action toggleLoading = (value) => (this.loading = value)

  fetchPaymentCountries = async (username: string) => {
    const { data } = await this.childApi.fetchPaymentCountries(username)

    if (data) {
      this.paymentCountries = data
    }

    this.toggleLoading(false)
  }
}
