import { makeObservable } from 'mobx'

import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { apiClient } from 'utils/requests.utils'

import SharedStore from './shared.store'

import {
  AffiliateMarketplaceCategoriesCountsApi,
  createAffiliateMarketplaceCategoriesCounts,
} from '../api/affiliateMarkteplaceCategoriesCounts.api'

export class AffiliateMarketplaceCategoriesCountsStore extends SharedStore<any> {
  storeName = 'AffiliateMarketplaceCategoriesCountsStore'
  declare childApi: AffiliateMarketplaceCategoriesCountsApi

  constructor(root?: ShopRootStore) {
    super()
    makeObservable(this)

    this.childApi = createAffiliateMarketplaceCategoriesCounts(root?.apiClient ?? apiClient)
  }
}

export default new AffiliateMarketplaceCategoriesCountsStore()
