import type { PaginatedResponse } from 'types/responses'
import { ApiClient } from 'utils/requests.utils'

const BASE_URL = '/common/affiliate_marketplace/categories/counts'

export interface AffiliateMarketplaceCategoriesCount {
  id: number
  key: string
  unit: string
  currencyId: string
}

export const createAffiliateMarketplaceCategoriesCounts = ({ GET }: ApiClient) => ({
  fetchList: (data): Promise<PaginatedResponse<AffiliateMarketplaceCategoriesCount[]>> => GET(BASE_URL, data),
})

export type AffiliateMarketplaceCategoriesCountsApi = ReturnType<typeof createAffiliateMarketplaceCategoriesCounts>
